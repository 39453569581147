import React from "react"
import { Link } from "gatsby"

import HomeLayout from "../components/homelayout"

const Home = () => (
  <HomeLayout/>
)

export default Home
